import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ParallaxProvider, Parallax } from "react-skrollr";
import { Events, animateScroll } from "react-scroll";
import _ from "lodash";

import logo from "../assets/logo.svg";
import roller from "../assets/roller.svg";
import leaf0 from "../assets/leaf-0.svg";
import leaf1 from "../assets/leaf-1.svg";
import leaf2 from "../assets/leaf-2.svg";
import leaf3 from "../assets/leaf-3.svg";
import leaf4 from "../assets/leaf-4.svg";
import leaf5 from "../assets/leaf-5.svg";
import leaf6 from "../assets/leaf-6.svg";
import leaf7 from "../assets/leaf-7.svg";
import leaf8 from "../assets/leaf-8.svg";
import leaf9 from "../assets/leaf-9.svg";
import cupboard from "../assets/cupboard.svg";
import path from "../assets/path.svg";
import cover1 from "../assets/01-cover.svg";
import cover2 from "../assets/02-cover.svg";
import cover2_ from "../assets/02-cover-2.svg";
import cover3 from "../assets/03-cover.svg";
import cover4 from "../assets/04-cover.svg";
import cover5 from "../assets/05-cover.svg";
import cover5_ from "../assets/05-cover-2.svg";
import mobileLanding from "../assets/mobile-landing.svg";
import mobileRecycling from "../assets/mobile-recycling.svg";
import mobileDrop from "../assets/mobile-drop.svg";
import mobileCupboard from "../assets/mobile-cupboard.svg";
import mobile1 from "../assets/mobile-01.svg";
import mobile2 from "../assets/mobile-02.svg";
import mobile3 from "../assets/mobile-03.svg";
import mobile4 from "../assets/mobile-04.svg";
import mobile5 from "../assets/mobile-05.svg";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogoLoaded: false,
      hasLoaded: false,
      hideLogo: false,
      leaves: [
        leaf0,
        leaf1,
        leaf2,
        leaf3,
        leaf4,
        leaf5,
        leaf6,
        leaf7,
        leaf8,
        leaf9,
      ],
      onFormSubmit: "",
      onFormSuccessfullySent: "",
    };
    this.logoHasLoaded = this.logoHasLoaded.bind(this);
    this.keyboardEvents = this.keyboardEvents.bind(this);
    this.mobileScroll = this.mobileScroll.bind(this);
    //Home.debouncedScrollEvents = _.debounce(Home.debouncedScrollEvents, 100);
    Home.mouseWheelHandler = _.debounce(Home.mouseWheelHandler, 100);
    //window.scrollDirection = false; //false is down
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    // Scroll to top
    window.scrollTo(0, 0);
    // Add listeners
    if (!Home.isMobile()) {
      window.ignore = false;
      this.addScrollListeners();
      document.addEventListener("keydown", this.keyboardEvents);
    } else {
      document.addEventListener("scroll", this.mobileScroll);
    }
  }
  componentWillUnmount() {
    //Remove listeners
    if (!Home.isMobile()) {
      //window.removeEventListener("scroll", Home.debouncedScrollEvents);
      //window.removeEventListener("resize", Home.debouncedScrollEvents);
      this.removeScrollListeners();
      document.removeEventListener("keydown", this.keyboardEvents);
    } else {
      document.removeEventListener("scroll", this.mobileScroll);
    }
  }
  keyboardEvents(e) {
    // Spacebar and down arrow
    if (e.keyCode === 32 || e.keyCode === 40) {
      e.preventDefault();
      document.removeEventListener("keydown", this.keyboardEvents);
      animateScroll.scrollMore(window.innerHeight, {
        duration: 1000,
        ease: "easeInOutCubic",
        ignoreCancelEvents: true,
      });
      this.revealAnimationTrigger();
      setTimeout(
        () => document.addEventListener("keydown", this.keyboardEvents),
        1000
      );
      return false;
      // Up arrow
    } else if (e.keyCode === 38) {
      e.preventDefault();
      document.removeEventListener("keydown", this.keyboardEvents);
      animateScroll.scrollMore(-window.innerHeight, {
        duration: 1000,
        ease: "easeInOutCubic",
        ignoreCancelEvents: true,
      });
      setTimeout(
        () => document.addEventListener("keydown", this.keyboardEvents),
        1000
      );
      return false;
    }
  }
  static isMobile() {
    return /Android|iPhone|iPad|iPod|BlackBerry/i.test(
      navigator.userAgent || navigator.vendor || window.opera
    );
  }
  /* static debouncedScrollEvents() {
    if (!window.ignore) {
      const _sections = document.querySelectorAll(".window:not(.ignore)");
      const sections = Array.prototype.slice.call(_sections);
      const step = 60; //pixels
      const duration = 1000; //ms
      sections.every((item, i) => {
        const top = item.getBoundingClientRect().top;
        console.log(top);
        if (top <= window.innerHeight - step && top > -step) {
          if (!window.scrollDirection) {
            animateScroll.scrollTo(window.innerHeight * i, {
              duration: duration,
              smooth: "easeInOutQuart",
            });
          } else {
            animateScroll.scrollTo(window.innerHeight * (i - 1), {
              duration: duration,
              smooth: "easeInOutQuart",
            });
          }
        }
        return true;
      });
    }
  } */
  normalScrollEvents(e) {
    Home.mouseWheelHandler(e);
  }

  static mouseWheelHandler(e) {
    if (!window.ignore) {
      var e = window.event || e;
      window.delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail));
      if (window.delta === -1) {
        animateScroll.scrollMore(window.innerHeight, {
          duration: 1000,
          ease: "easeInOutCubic",
          ignoreCancelEvents: true,
        });
      } else {
        animateScroll.scrollMore(-window.innerHeight, {
          duration: 1000,
          ease: "easeInOutCubic",
          ignoreCancelEvents: true,
        });
      }
      window.ignore = true;
      setTimeout(() => {
        window.ignore = false;
      }, 1000);
    }
    // reveal animation trigger (static)
    document.querySelectorAll(".window:not(.ignore)").forEach((el, i) => {
      if (
        el.getBoundingClientRect().top >= 0 &&
        el.getBoundingClientRect().top <
          (window.innerHeight || document.documentElement.clientHeight)
      )
        setTimeout(() => {
          document
            .querySelectorAll(".window:not(.ignore)")
            [i + 1].classList.add("react");
        }, 300);
    });
  }
  revealAnimationTrigger() {
    document.querySelectorAll(".window:not(.ignore)").forEach((el, i) => {
      if (
        el.getBoundingClientRect().top >= 0 &&
        el.getBoundingClientRect().top <
          (window.innerHeight || document.documentElement.clientHeight)
      )
        setTimeout(() => {
          document
            .querySelectorAll(".window:not(.ignore)")
            [i + 1].classList.add("react");
        }, 300);
    });
  }
  mobileScroll() {
    this.revealAnimationTrigger();
  }
  logoHasLoaded() {
    this.setState({ isLogoLoaded: true });
  }
  getRandomIntBw(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }
  getRandomScaleBw(max, min) {
    return Math.random() * (max - min) + min;
  }
  addScrollListeners() {
    if (document.body.addEventListener) {
      if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
        // Firefox
        document.addEventListener("DOMMouseScroll", this.normalScrollEvents, {
          passive: false,
        });
      } else {
        // IE9, Chrome, Safari, Opera
        document.addEventListener("mousewheel", this.normalScrollEvents, {
          passive: false,
        });
      }
      // IE 6~8
    } else document.attachEvent("onmousewheel", this.normalScrollEvents);
  }
  removeScrollListeners() {
    if (document.body.addEventListener) {
      if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
        // Firefox
        document.removeEventListener(
          "DOMMouseScroll",
          this.normalScrollEvents,
          false
        );
      } else {
        // IE9, Chrome, Safari, Opera
        document.removeEventListener(
          "mousewheel",
          this.normalScrollEvents,
          false
        );
      }
      // IE 6~8
    } else document.detachEvent("onmousewheel", this.normalScrollEvents);
  }
  handleSubmit(e) {
    e.preventDefault();
    e.target.getElementsByTagName("input")[0].blur();
    this.setState({ onFormSubmit: "loading" });
    // Execute after success

    // Execute after error
  }
  render() {
    return (
      <ParallaxProvider
        init={{
          smoothScrolling: false,
          forceHeight: true,
        }}
      >
        <div className="container">
          <section className="window first ignore">
            <img
              src={logo}
              className={"logo " + (this.state.isLogoLoaded ? "show" : "")}
              onLoad={this.logoHasLoaded}
            />
          </section>
          <section className="window second">
            <div className="content">
              <h1>Hard to break up with your old clothes?</h1>
              <p>We’ll pay you for them, as you shop for new ones!</p>
            </div>
            <img src={mobileLanding} className="mobile-landing" />
          </section>
          <Parallax
            data={{
              "data-start":
                "transform: translateY(0vh) translateX(0px); width: 100%; margin-left: 0%;",
              "data-100p-start":
                "transform: translateY(50vh) translateX(60px); width: 50%; margin-left: 50%;",
              "data-200p-start":
                "transform: translateY(150vh) translateX(150px); width: 50%; margin-left: 50%;",
              "data-300p-start":
                "transform: translateY(250vh) translateX(150px); width: 50%; margin-left: 50%;",
              "data-400p-start":
                "transform: translateY(400vh) translateX(0px); width: 50%; margin-left: 50%;",
              "data-425p-start":
                "transform: translateY(400vh) translateX(0px); width: 50%; margin-left: 50%;",
              "data-500p-start":
                "transform: translateY(450vh) translateX(0px); width: 50%; margin-left: 0%;",
              "data-525p-start":
                "transform: translateY(450vh) translateX(0px); width: 50%; margin-left: 0%;",
              "data-584p-start":
                "transform: translateY(550vh) translateX(0px); width: 50%; margin-left: 25%;",
              "data-624p-start":
                "transform: translateY(550vh) translateX(0px); width: 50%; margin-left: 25%;",
              "data-684p-start":
                "transform: translateY(650vh) translateX(0px); width: 50%; margin-left: 25%;",
              "data-724p-start":
                "transform: translateY(650vh) translateX(0px); width: 50%; margin-left: 25%;",
              "data-784p-start":
                "transform: translateY(750vh) translateX(0px); width: 50%; margin-left: 25%;",
              "data-824p-start":
                "transform: translateY(750vh) translateX(0px); width: 50%; margin-left: 25%;",
              "data-884p-start":
                "transform: translateY(850vh) translateX(0px); width: 50%; margin-left: 25%;",
              "data-924p-start":
                "transform: translateY(850vh) translateX(0px); width: 50%; margin-left: 25%;",
            }}
          >
            <section
              className={"leaves " + (this.props.beginAnimation ? "ready" : "")}
            >
              <Parallax
                data={{
                  "data-375p-start":
                    "opacity: 0; top: 50%; left: 50%; transform: translate(-40%, 0%) scale(.6);",
                  "data-400p-start":
                    "opacity: 1; top: 50%; left: 50%; transform: translate(-40%, -84%) scale(.84);",
                  "data-425p-start":
                    "opacity: 1; top: 50%; left: 50%; transform: translate(-40%, -100%) scale(.84);",
                  "data-450p-start":
                    "opacity: 0; top: 50%; left: 50%; transform: translate(-40%, -200%) scale(1);",
                }}
              >
                <img src={cupboard} />
              </Parallax>
              <Parallax
                data={{
                  "data-475p-start":
                    "height: 0px; transform: translateY(-50%) translateX(240px);",
                  "data-500p-start":
                    "height: 630px; transform: translateY(-50%) translateX(240px);",
                  "data-525p-start":
                    "height: 630px; transform: translateY(-50%) translateX(240px);",
                  "data-550p-start":
                    "height: 0px; transform: translateY(-50%) translateX(240px);",
                  "data-575p-start":
                    "height: 0px; transform: translateY(-30%) translateX(240px);",
                  "data-600p-start":
                    "height: 630px; transform: translateY(-30%) translateX(240px);",
                  "data-625p-start":
                    "height: 630px; transform: translateY(-30%) translateX(240px);",
                  "data-650p-start":
                    "height: 0px; transform: translateY(-30%) translateX(240px);",
                  "data-675p-start":
                    "height: 0px; transform: translateY(-40%) translateX(-120px);",
                  "data-700p-start":
                    "height: 630px; transform: translateY(-40%) translateX(-120px);",
                  "data-725p-start":
                    "height: 630px; transform: translateY(-40%) translateX(-120px);",
                  "data-750p-start":
                    "height: 0px; transform: translateY(-30%) translateX(-120px);",
                  "data-775p-start":
                    "height: 0px; transform: translateY(-30%) translateX(-120px);",
                  "data-800p-start":
                    "height: 630px; transform: translateY(-30%) translateX(-120px);",
                  "data-825p-start":
                    "height: 630px; transform: translateY(-30%) translateX(-120px);",
                  "data-850p-start":
                    "height: 0px; transform: translateY(-30%) translateX(-120px);",
                  "data-875p-start":
                    "height: 0px; transform: translateY(-40%) translateX(-120px);",
                  "data-900p-start":
                    "height: 630px; transform: translateY(-40%) translateX(-120px);",
                  "data-925p-start":
                    "height: 630px; transform: translateY(-40%) translateX(-120px);",
                  "data-950p-start":
                    "height: 0px; transform: translateY(-40%) translateX(-120px);",
                }}
              >
                <div className="green-box"></div>
              </Parallax>
              <Parallax
                data={{
                  "data-475p-start":
                    "opacity: 0; transform: translateY(-50%) translateX(120px) rotate(15deg);",
                  "data-500p-start":
                    "opacity: 1; transform: translateY(-50%) translateX(120px) rotate(0deg);",
                  "data-525p-start":
                    "opacity: 1; transform: translateY(-50%) translateX(120px) rotate(0deg);",
                  "data-550p-start":
                    "opacity: 0; transform: translateY(-60%) translateX(120px) rotate(0deg);",
                }}
              >
                <img src={cover1} />
              </Parallax>
              <Parallax
                data={{
                  "data-575p-start":
                    "opacity: 0; transform: translateY(-4%) translateX(540px) rotate(30deg); transform-origin: left bottom;",
                  "data-600p-start":
                    "opacity: 1; transform: translateY(-4%) translateX(540px) rotate(0deg); transform-origin: left bottom;",
                  "data-625p-start":
                    "opacity: 1; transform: translateY(-4%) translateX(540px) rotate(0deg); transform-origin: left bottom;",
                  "data-650p-start":
                    "opacity: 0; transform: translateY(-4%) translateX(540px) rotate(-30deg); transform-origin: left bottom;",
                }}
              >
                <img src={cover2} />
              </Parallax>
              <Parallax
                data={{
                  "data-575p-start":
                    "opacity: 0; transform: translateX(300px) translateY(-6%);",
                  "data-600p-start":
                    "opacity: 1; transform: translateX(300px) translateY(-12%);",
                  "data-625p-start":
                    "opacity: 1; transform: translateX(300px) translateY(-12%);",
                  "data-650p-start":
                    "opacity: 0; transform: translateX(300px) translateY(-6%);",
                }}
              >
                <img src={cover2_} />
              </Parallax>
              <Parallax
                data={{
                  "data-675p-start":
                    "opacity: 0; transform: translateX(-450px);",
                  "data-700p-start":
                    "opacity: 1; transform: translateX(-120px);",
                  "data-725p-start":
                    "opacity: 1; transform: translateX(-120px);",
                  "data-750p-start":
                    "opacity: 0; transform: translateX(450px);",
                }}
              >
                <img src={cover3} />
              </Parallax>
              <Parallax
                data={{
                  "data-775p-start":
                    "opacity: 0; transform: translateX(-120px) translateY(-18%);",
                  "data-800p-start":
                    "opacity: 1; transform: translateX(-120px) translateY(-24%);",
                  "data-825p-start":
                    "opacity: 1; transform: translateX(-120px) translateY(-24%);",
                  "data-850p-start":
                    "opacity: 0; transform: translateX(-120px) translateY(-30%);",
                }}
              >
                <img src={cover4} />
              </Parallax>
              <Parallax
                data={{
                  "data-875p-start":
                    "opacity: 0; transform: translateX(60px) translateY(-18%);",
                  "data-900p-start":
                    "opacity: 1; transform: translateX(60px) translateY(-24%);",
                  "data-925p-start":
                    "opacity: 1; transform: translateX(60px) translateY(-24%);",
                  "data-950p-start":
                    "opacity: 0; transform: translateX(60px) translateY(-30%);",
                }}
              >
                <img src={cover5_} />
              </Parallax>
              <Parallax
                data={{
                  "data-875p-start":
                    "opacity: 0; transform: translateX(360px) translateY(-24%);",
                  "data-900p-start":
                    "opacity: 1; transform: translateX(270px) translateY(-24%);",
                  "data-925p-start":
                    "opacity: 1; transform: translateX(270px) translateY(-24%);",
                  "data-950p-start":
                    "opacity: 0; transform: translateX(180px) translateY(-24%);",
                }}
              >
                <img src={cover5} />
              </Parallax>
              <Parallax
                data={{
                  "data-475p-start":
                    "opacity: 0; transform: translateY(-180px)",
                  "data-500p-start":
                    "opacity: 1; transform: translateY(-300px);",
                  "data-525p-start":
                    "opacity: 1; transform: translateY(-300px);",
                  "data-550p-start":
                    "opacity: 0; transform: translateY(-420px);",
                }}
              >
                <h1 className="order">01</h1>
              </Parallax>
              <Parallax
                data={{
                  "data-575p-start":
                    "opacity: 0; transform: translateX(12vw) translateY(-180px);",
                  "data-600p-start":
                    "opacity: 1; transform: translateX(12vw) translateY(-240px);",
                  "data-625p-start":
                    "opacity: 1; transform: translateX(12vw) translateY(-240px);",
                  "data-650p-start":
                    "opacity: 0; transform: translateX(12vw) translateY(-300px);",
                }}
              >
                <h1 className="order">02</h1>
              </Parallax>
              <Parallax
                data={{
                  "data-675p-start":
                    "opacity: 0; transform: translateX(21vw) translateY(-180px);",
                  "data-700p-start":
                    "opacity: 1; transform: translateX(21vw) translateY(-240px);",
                  "data-725p-start":
                    "opacity: 1; transform: translateX(21vw) translateY(-240px);",
                  "data-750p-start":
                    "opacity: 0; transform: translateX(21vw) translateY(-300px);",
                }}
              >
                <h1 className="order">03</h1>
              </Parallax>
              <Parallax
                data={{
                  "data-775p-start":
                    "opacity: 0; transform: translateX(21vw) translateY(-180px);",
                  "data-800p-start":
                    "opacity: 1; transform: translateX(21vw) translateY(-240px);",
                  "data-825p-start":
                    "opacity: 1; transform: translateX(21vw) translateY(-240px);",
                  "data-850p-start":
                    "opacity: 0; transform: translateX(21vw) translateY(-300px);",
                }}
              >
                <h1 className="order">04</h1>
              </Parallax>
              <Parallax
                data={{
                  "data-875p-start":
                    "opacity: 0; transform: translateX(-240px) translateY(-180px);",
                  "data-900p-start":
                    "opacity: 1; transform: translateX(-240px) translateY(-300px);",
                  "data-925p-start":
                    "opacity: 1; transform: translateX(-240px) translateY(-300px);",
                  "data-950p-start":
                    "opacity: 0; transform: translateX(-240px) translateY(-360px);",
                }}
              >
                <h1 className="order">05</h1>
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 20%; top: -24vh; transform: rotate(-291deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 90%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 51%; top: -13.5vh; transform: rotate(-27deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: 87%; top: -64.5vh; transform: rotate(30deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-0"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 30%; top: -24vh; transform: rotate(309deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 75%; top: -18vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 42%; top: -11.5vh; transform: rotate(-339deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: 85.5%; top: -38.5vh; transform: rotate(-34.5deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-1"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 40%; top: -24vh; transform: rotate(114deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 54%; top: -33vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 39%; top: -6vh; transform: rotate(9deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: 33%; top: -38vh; transform: rotate(38.5deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-2"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 60%; top: -24vh; transform: rotate(-315deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 66%; top: -48vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 57.5%; top: -7.5vh; transform: rotate(342deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: 21%; top: -54vh; transform: rotate(-33deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-3"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 70%; top: -24vh; transform: rotate(-60deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 39%; top: -4.5vh; transform: rotate(39deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: 12%; top: -44vh; transform: rotate(-27deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-4"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 80%; top: -24vh; transform: rotate(-42deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 60%; top: 0vh; transform: rotate(12deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: 5%; top: -28vh; transform: rotate(-9deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-5"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 10%; top: -12vh; transform: rotate(-36deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 38.5%; top: 4.5vh; transform: rotate(-36deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-9, 3) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-6"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 20%; top: -12vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 36.8%; top: 10vh; transform: rotate(-48deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-9, 3) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-7"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 30%; top: -12vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 58.5%; top: 6vh; transform: rotate(-16.5deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-9, 3) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-8"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 40%; top: -12vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 45%; top: 10vh; transform: rotate(45deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-9, 3) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-9"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 50%; top: -12vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 45%; top: 13vh; transform: rotate(81deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-9, 3) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-10"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 60%; top: -12vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 48.5%; top: 13.5vh; transform: rotate(-82.5deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-9, 3) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-11"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 70%; top: -12vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 54%; top: -13vh; transform: rotate(-300deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-9, 3) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-12"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 80%; top: -12vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-9, 3) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-13"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 90%; top: -12vh; transform: rotate(18deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-9, 3) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-14"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 16%; top: 2vh; transform: rotate(-203deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 39%; top: -1.5vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-9, 3) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-15"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 30%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 45%; top: 7.5vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-9, 3) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-16"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 40%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 57%; top: 0vh; transform: rotate(-30deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-9, 3) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-17"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 54%; top: -8.5vh; transform: rotate(-57deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-9, 3) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-18"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 60%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 60%; top: -12vh; transform: rotate(-60deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-9, 3) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-19"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 70%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 48%; top: -10.5vh; transform: rotate(231deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-9, 3) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-20"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 80%; top: 0vh; transform: rotate(-192deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 30%; top: -7.5vh; transform: rotate(91.5deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-9, 3) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-21"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 27%; top: 12vh; transform: rotate(-60deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 22.5%; top: -1.5vh; transform: rotate(28.5deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-9, 3) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-22"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 40%; top: 12vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 27%; top: 0vh; transform: rotate(67.5deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-9, 3) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-23"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 50%; top: 12vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 36%; top: 3vh; transform: rotate(12deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-9, 3) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-24"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 60%; top: 12vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 39%; top: 12vh; transform: rotate(-15deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-9, 3) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-25"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 73%; top: 12vh; transform: rotate(192deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 51%; top: 13.5vh; transform: rotate(246deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-9, 3) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-26"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 40%; top: 24vh; transform: rotate(129deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-9, 3) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-27"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 50%; top: 24vh; transform: rotate(150deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 60%; top: 7.5vh; transform: rotate(162deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-9, 3) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-28"
                />
              </Parallax>
              <Parallax
                data={{
                  "data-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-15, 10) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                  "data-100p-start":
                    "left: 60%; top: 24vh; transform: rotate(222deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-200p-start":
                    "left: 63%; top: 7.5vh; transform: rotate(-51.5deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-300p-start":
                    "left: 50%; top: 0vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-400p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-9, 3) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.6, 0.9) +
                    ");",
                  "data-500p-start":
                    "left: " +
                    this.getRandomIntBw(0, 100) +
                    "%; top: " +
                    this.getRandomIntBw(-108, -100) +
                    "vh; transform: rotate(" +
                    this.getRandomIntBw(-360, 360) +
                    "deg) scale(" +
                    this.getRandomScaleBw(0.4, 1) +
                    ");",
                }}
              >
                <img
                  src={
                    this.state.leaves[
                      Math.floor(Math.random() * this.state.leaves.length)
                    ]
                  }
                  className="leaf leaf-29"
                />
              </Parallax>
            </section>
          </Parallax>
          <section className="window third">
            <div className="content">
              <h2>Shed the old, before the new</h2>
              <p>
                Through Indyra, seamlessly circulate clothes out of your closet,
                have them picked up from your doorstep, and earn shopping coins
                that are valid on new purchases.
              </p>
              <p>
                Now, recycle and upcycle the old while embracing the new - all
                at once. Get rewarded in more ways than one for being
                responsible.
              </p>
              <div className="box">
                <Link to="/recycling-value">Know more</Link>
              </div>
            </div>
            <img src={mobileRecycling} className="mobile-recycling" />
          </section>
          <section className="window fourth">
            <div className="content">
              <h2>
                (Yes, you heard right!)&nbsp;
                <span>50%</span>&nbsp;of items in the modern wardrobe are
                underused. Sound all too familiar?
              </h2>
            </div>
          </section>
          <section className="window mobile-sustainable ignore">
            <div className="content">
              <img src={mobileDrop} />
              <h2>
                (Yes, you heard right!)
                <span>50%</span> of items in the modern wardrobe are underused.
                Sound all too familiar?
              </h2>
            </div>
          </section>
          <section className="window fifth">
            <div className="content">
              <h2>
                Conscious fashion approaches like upcycling, resale and
                closed-loop recycling can save <span>15%</span> of carbon
                emitted by the community.
              </h2>
              <div className="box">
                <Link to="/recycling-value">
                  How is Indyra making this happen?
                </Link>
              </div>
            </div>
          </section>
          <section className="window mobile-how ignore">
            <div className="content">
              <img src={mobileCupboard} />
            </div>
          </section>
          <section className="window sixth">
            <div className="content">
              <h3>Here’s how. Go circular, join Indyra.</h3>
              <p>Our services</p>
              <ul>
                <li>
                  <h4>Fashion Circulation</h4>
                  <p>
                    Circulate clothes through Indyra with doorstep pickup and
                    instant payout in the form of “Indyra coins”
                  </p>
                </li>
                <li>
                  <h4>A curated shopping centre</h4>
                  <p>
                    Use your Indyra coins to shop from 300+ fashion and
                    lifestyle brands
                  </p>
                </li>
              </ul>
              <div className="box">
                <Link to="/recycling-value">How does Indyra work?</Link>
              </div>
            </div>
          </section>
          <section className="window mobile-01 ignore">
            <div className="content">
              <img src={mobile1} />
            </div>
          </section>
          <section className="path">
            <img src={path} />
          </section>
          <section className="window seventh">
            <div className="content">
              <h3>Reorganize your home & wardrobe</h3>
              <p>
                It’s time to reorganize and circulate! Pull out all the
                garments, accessories and even toys you no longer use and sort
                them into boxes. Bid adieu as you welcome the new.
              </p>
            </div>
          </section>
          <section className="window mobile-02 ignore">
            <div className="content">
              <img src={mobile2} />
            </div>
          </section>
          <section className="window eighth">
            <div className="content">
              <h3>Get an estimate</h3>
              <p>
                Upload photos of the items you want to circulate through us and
                receive an instant payout estimate, paid in the form of Indyra
                coins.
              </p>
              <div className="box">
                <Link to="/recycling-value">
                  Try out our payout calculator right now!
                </Link>
              </div>
            </div>
          </section>
          <section className="window mobile-03 ignore">
            <div className="content">
              <img src={mobile3} />
            </div>
          </section>
          <section className="window ninth">
            <div className="content">
              <h3>Book pickup</h3>
              <p>
                As per your convenience, book a doorstep pickup at home, or earn
                extra coins by dropping off the pieces at our nearest drop
                centre.
              </p>
            </div>
          </section>
          <section className="window mobile-04 ignore">
            <div className="content">
              <img src={mobile4} />
            </div>
          </section>
          <section className="window tenth">
            <div className="content">
              <h3>Get paid in Indyra coins</h3>
              <p>
                After pickup, the items are evaluated at our warehouse and based
                on their condition, the payout in the form of Indyra coins is
                credited to your account within 48 hours.
              </p>
            </div>
          </section>
          <section className="window mobile-05 ignore">
            <div className="content">
              <img src={mobile5} />
            </div>
          </section>
          <section className="window eleventh">
            <div className="content">
              <h3> Shop with Coins</h3>
              <p>
                Continue your circular journey by redeeming these coins on your
                new purchase from our carefully curated partner brands which are
                available both, online and offline.
              </p>
              <p>
                Love what you buy to the fullest, and when you feel it fading,
                repeat the recycling process again. We’re just a click away. -
                Another slide/somewhere else?
              </p>
            </div>
          </section>
          <section className="window twelfth">
            <div className="content">
              <h3>
                Be a fashion circulator!
                <br />
                Activate your subscription at
                <br />
                <span>
                  <b>£ 29</b>annually
                </span>
              </h3>
              <Link to="/sign-up" className="join">
                Join Indyra
              </Link>
              <Link to="/recycling-value" className="know">
                FAQ
              </Link>
              <h6>As a member you get,</h6>
              <ul>
                <li>
                  <p>Indyra’s eco-friendly recycling is for members only.</p>
                </li>
                <li>
                  <p>
                    Enjoy unlimited quotes via chat support and unlimited
                    pickups.
                  </p>
                </li>
                <li>
                  <p>
                    Our members earn more than 5 times of this cost in form of
                    points as they offset the emitted carbons, responsibly!
                  </p>
                </li>
              </ul>
              <Link to="/recycling-value" className="how">
                See how you can earn £300 by recycling
              </Link>
            </div>
            <img src={logo} />
          </section>
          <section className="window thirteenth">
            <div className="content">
              <h2>Contact us</h2>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText("info@indyra.eco");
                  e.target.getElementsByTagName("span")[0].textContent =
                    "Copied to clipboard!";
                }}
                className="email"
                alt="Click to copy"
              >
                info@indyra.eco<span>Click to copy to clipboard</span>
              </a>
              <div className="social">
                <a href="https://www.facebook.com/goindyra" target="_blank">
                  Facebook
                </a>
                <a href="https://www.instagram.com/goindyra/" target="_blank">
                  Instagram
                </a>
                <a href="https://twitter.com/goindyra" target="_blank">
                  Twitter
                </a>
                <a
                  href="https://www.linkedin.com/company/indyra/"
                  target="_blank"
                >
                  LinkedIn
                </a>
              </div>
              <div className="address">
                <div className="col">
                  86-90 Paul Street
                  <br />
                  London, United Kingdom
                  <br />
                  EC2A 4NE
                </div>
                <div className="col">
                  Plot No 83/2, Badgaon,
                  <br />
                  Udaipur, Rajasthan, India
                  <br />
                  313 001
                </div>
              </div>
            </div>
            <div className="content">
              <p className="updates">
                Get weekly updates about Indyra, our case studies and tips right
                in your mailbox.
              </p>
              <form
                onSubmit={this.handleSubmit}
                className={
                  this.state.onFormSubmit +
                  " " +
                  this.state.onFormSuccessfullySent
                }
              >
                <label htmlFor="email">Email address</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Your email address"
                />
                <div className="loader"></div>
                <div className="line"></div>
                <span>
                  We will never share your email address or spam you.
                  <br />
                  You can unsubscribe anytime.
                </span>
                <button type="submit">Subscribe</button>
              </form>
            </div>
            <div className="copy">
              © Indyra 2022<a href="#">Privacy Policy</a>
            </div>
          </section>
          <section className="green-overlay"></section>
          <section
            className={"roller " + (this.props.beginAnimation ? "active" : "")}
          >
            <Parallax
              data={{
                "data-start": "transform: rotate(0deg);",
                "data-end": "transform: rotate(1080deg);",
              }}
            >
              <img src={roller} />
            </Parallax>
          </section>
        </div>
      </ParallaxProvider>
    );
  }
}
