import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import logo from '../assets/logo.svg';
import arrowBack from '../assets/arrow-back.svg';

export default class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <div className="sign-up">
        <div className="container">
          <section className="window">
            <div className="content">
              <p>Signup for a membership at</p>
              <h2>
                <b>£ 29</b>
                annually (INR 999)</h2>
              <h6>As a member you get,</h6>
              <ul>
                <li>
                  <p>Indyra’s eco-friendly recycling is for members only.</p>
                </li>
                <li>
                  <p>Enjoy unlimited quotes via chat support and unlimited pickups.</p>
                </li>
                <li>
                  <p>Our members earn more than 5 times of this cost in form of points as they offset the emitted carbons, responsibly!</p>
                </li>
              </ul>
            </div>
            <div className="content">
              <h2>Payment gateway here...</h2>
            </div>
            <Link to="/">
              <img src={arrowBack}/>
              <img src={logo}/>
            </Link>
          </section>
        </div>
        <div className="bg"></div>
      </div>
    )
  }
}
