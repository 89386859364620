import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import logo from '../assets/logo.svg';
import arrowBack from '../assets/arrow-back.svg';

export default class RecyclingValue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPoints: 0
    }
  }
  getTwoDigits(n) {
    return n.toLocaleString('en-US', {minimumIntegerDigits: 2});
  }
  render() {
    return (<div className="recycling-value">
      <div className="container">
        <section className="window">
          <div className="content">
            <p>Step 01 of 02</p>
            <h3>Let’s see how many points can you earn by recycling&nbsp;
              <span>( while saving the planet )</span>
            </h3>
            <h6>Select an action</h6>
            <button>I want to discard my clothes</button>
            <button>I want to trade my clothes</button>
          </div>
          <Link to="/sign-up" className="back">
            <img src={arrowBack}/>
            Back to sign up page
          </Link>
          <a href="#" className="recycle">Recycle clothes</a>
          <div className="count">
            <p>Your total Indyra Points</p>
            <h1>{this.getTwoDigits(this.state.totalPoints)}</h1>
            <span>Trade In or recycle your clothes to earn to more points here</span>
          </div>
        </section>
      </div>
    </div>)
  }
}
