import React, { Component } from "react";
import { useLocation, Link, Routes, Route } from "react-router-dom";
import FontFaceObserver from "fontfaceobserver";

import Home from "./pages/Home";
import SignUp from "./pages/SignUp";
import RecyclingValue from "./pages/RecyclingValue";

import logo from "./assets/logo.svg";

export const withLocation = (Component: Component) => {
  return (props) => <Component {...props} location={useLocation()} />;
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      beginAnimation: false,
      isNavVisible: true,
    };
  }
  componentDidMount() {
    const clash = new FontFaceObserver("Clash");
    const satoshi = new FontFaceObserver("Satoshi");
    Promise.all([clash.load(null, 6000), satoshi.load(null, 6000)]).then(() => {
      // Add loaded class to body
      document.body.classList.add("loaded");
      setTimeout(() => {
        // Animate in main nav and trigger home page animation
        this.setState({ beginAnimation: true });
      }, 3000);
    });
    if (this.isMobile()) {
      document.getElementsByTagName("html")[0].classList.add("mobile");
      document.getElementsByTagName("body")[0].classList.add("mobile");
    }
  }
  static getDerivedStateFromProps(props, state) {
    // Add class to nav on sign-up page
    if (
      props.location.pathname === "/sign-up" ||
      props.location.pathname === "/recycling-value"
    ) {
      return { isNavVisible: false };
    } else {
      return { isNavVisible: true };
    }
    return null;
  }
  isMobile() {
    return /Android|iPhone|iPad|iPod|BlackBerry/i.test(
      navigator.userAgent || navigator.vendor || window.opera
    );
  }
  render() {
    return (
      <>
        <nav
          className={
            (this.state.beginAnimation ? "ready" : "") +
            " " +
            (!this.state.isNavVisible ? "hide" : "")
          }
        >
          <div className="nav-container">
            <Link to="/">
              <img src={logo} className="logo" />
            </Link>
            <div className="menu">
              <ul>
                <li>
                  <a href="#">Carbon Footprint</a>
                </li>
                <li>
                  <a href="#">Our manifesto</a>
                </li>
                <li>
                  <a href="#">Circulation</a>
                </li>
                <li>
                  <a href="#">Contact</a>
                </li>
                <li>
                  <Link to="/sign-up">Login / Sign Up</Link>
                </li>
              </ul>
            </div>
            <div className="line"></div>
          </div>
        </nav>
        <Routes>
          {/*<Route path="/recycling-value" element={<RecyclingValue />} />
          <Route path="/sign-up" element={<SignUp />} />*/}
          <Route path="/" element={<Home />} />
        </Routes>
      </>
    );
  }
}

export default withLocation(App);
